@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Toyota Display";
  src: url("/assets/fonts/toyota/ToyotaType-Regular.ttf");
}

html {
  height: 100%;
  overflow: auto;
}
body {
  height: 100%;
}

body {
  @apply bg-gray-100;
}

.page {
  @apply mt-12;
}

/* Aktion Überschriften (Ewe einholen...) */

.headline {
  @apply text-lg text-gray-600 font-semibold uppercase mt-4;
}

.button-tap-headline {
  @apply headline text-center;
}

/* MODALS */

/* Prevent scrolling while modal is shown */
body.modal-showing {
  overflow: hidden;
}

.modal-button-container {
  @apply flex flex-row items-center justify-between mt-8 border-t pt-4;
}

.modal-button-container-ok-cancel {
  @apply flex flex-row items-center justify-center gap-4 mt-8 border-t pt-4;
}

.modal-button {
  @apply flex items-center justify-center gap-4 rounded-xl w-[220px] shadow bg-white py-2 px-4 hover:bg-toyotaRed hover:text-white duration-100 border border-gray-200 font-semibold disabled:animate-none disabled:bg-gray-200 text-gray-600 disabled:hover:text-gray-400 disabled:text-gray-400;
}

.modal-status-button {
  @apply flex items-center justify-center gap-4 rounded-xl shadow bg-white py-2 px-4 h-full border-gray-200 disabled:animate-none;
}

.modal-button.primary {
  @apply bg-toyotaRed text-white disabled:cursor-not-allowed disabled:bg-gray-400 hover:bg-toyotaRed;
}

/* Main Header Kundendaten / Einwilligung*/
.main-header {
  @apply flex items-center h-8 text-xl font-semibold uppercase text-gray-600;
}

/* Card - Gesamter Inhalt für zentriert mit Überschrift (in EWE Abschnitt)*/
.header-card-content {
  @apply flex flex-col items-center justify-center gap-1 text-sm text-center;
}

/* Card - Überschrift (ist innerhalb von card-content */
.card-header {
  @apply flex flex-row items-center justify-center gap-2 text-lg uppercase font-semibold text-gray-600;
}

/* Bearbeiten */
.edit-header {
  @apply flex flex-row justify-start items-center gap-4 text-lg border-b uppercase font-semibold text-gray-600;
}

/* Tabellen */

.table {
  @apply border;
}

.table-header {
  @apply bg-gray-200 text-left font-semibold border px-2;
}

.table-td {
  @apply p-4 border;
}

.table-td.contractPartner {
  @apply py-4 px-8 border w-[288px];
}
/* Rote Box  */

.red-box {
  @apply border border-2 border-toyotaRed p-8 mt-4;
}

/* Dealer Report */

.dealer-report-headline {
  @apply text-xl font-semibold mb-4;
}

.button {
  border: 1px solid #ddd;
  background: white;
  border-radius: 0.5rem;
  width: 233px;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  padding: 0 20px;

  &:disabled {
    border: transparent;

    &:hover {
      border: transparent;
      scale: 100%;
    }

    &:active {
      border: transparent;
    }

    &:focus {
      border: transparent;
    }
  }

  &:hover {
    border-color: black;
    background: white;
    color: black;

    & .label {
      color: black;
    }
  }
}

.btn-save {
  margin-left: 0.5rem;
  background-color: #cc0033;
  color: white;
  border-radius: 0.5rem;
  font-size: 14px;

  &:disabled {
    border: transparent;

    &:hover {
      border: transparent;
    }

    &:active {
      outline: transparent;
    }
  }

  &:hover {
    background-color: #a8012b;
    color: white;
  }

  &:active {
    background-color: #880123;
    border: 1px double white;
    outline: 1px solid #880123;
    color: white;
  }

  &:focus {
    border: 1px double white;
    outline: 1px solid #cc0033;
    color: white;
  }
}

.btn-save-lexus {
  margin-left: 0.5rem;
  background-color: #20365c;
  color: white;
  border-radius: 0.5rem;
  font-size: 14px;

  &:disabled {
    border: transparent;

    &:hover {
      border: transparent;
    }

    &:active {
      outline: transparent;
    }
  }

  &:hover {
    background-color: #182b4d;
    color: white;
  }

  &:active {
    background-color: #11203a;
    border: 1px double white;
    outline: 1px solid #11203a;
    color: white;
  }

  &:focus {
    border: 1px double white;
    outline: 1px solid #20365c;
    color: white;
  }
}

.btn-cancel {
  border: 1px solid #ddd;
  background: white;
  border-radius: 0.5rem;
  font-size: 14px;

  &:hover {
    border-color: black;
    background: white;
    color: black;

    & .label {
      color: black;
    }
  }
}

.form-group-column {
  background: #fafafa;
  padding: 10px;
  border-radius: 10px;
}

.textbox {
  width: 100%;
  height: 30px;
  min-height: 30px;
  display: block;
  border-radius: 0.5rem;
  padding-left: 4px;
  font-size: 15px;
  background-color: white !important;
  border: 2px solid #f0f0f0;
  color: #202020;
  box-sizing: border-box;
}

.textbox:hover {
  background-color: #dcdcdc;
  border: 2px solid #dcdcdc;
}

.textbox:focus {
  background-color: white;
  border: 2px solid white;
  box-shadow: 1px 1px 5px 1px gray;
}

/* Fehler Seiten */
.code {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 50rem;
  color: rgba(255, 255, 255, 0.6);
}

.message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: white;
  background-color: gray;
  padding: 20px 10px;
  line-height: 1;
}

/* Notifications */
.notification-wrapper {
  padding: 0.25rem;
  margin: 0.5rem auto;
  margin-top: 4rem;
  background-color: rgba(225, 0, 0, 0.9);
  text-align: center;
  width: auto;
  max-width: 95% !important;
}
.notification-wrapper li {
  font-size: 0.8rem;
  text-align: left;
  color: white;
  margin: 0.25rem;
}
.notification-wrapper ul {
  list-style: none;
}
.notification-wrapper .btn-notification-actions {
  text-align: center;
}
.notification-wrapper .btn-notification-actions button {
  margin: 0.25rem;
  border: none !important;
  border-radius: 0 !important;
  width: 10rem !important;
}

.no-notifications {
  display: none !important;
}

/* Material */
mat-tooltip-component .mdc-tooltip__surface {
  @apply text-sm p-2;
  max-height: unset !important;
}

.mat-select-panel,
.mat-mdc-text-field-wrapper,
mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
mdc-text-field--filled:hover {
  background: white !important;
  font-size: 14pt;
}

.mat-mdc-form-field-focus-overlay {
  background: white !important;
  font-size: 14pt;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: white;
}

.mat-primary
  .mat-mdc
  -option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: black;
}

.mat-mdc-option {
  margin-top: 1rem;
}
